import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router";
import { useAuth } from "../../hooks/useAuth";

export default function AdminLayoutControl() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  // console.log(location)
  const pathSegments = location.pathname.split("/");
  // console.log(pathSegments)
  let isAdminPath = pathSegments[1] === "admin" && pathSegments.length > 2;
  if (!user && isAdminPath) {
    return <Navigate to={"/admin"} />;
  }
  return (
    <>
      <Outlet />
    </>
  );

  // return <UserUnauthenticated navigate={navigate} />;
}

const UserUnauthenticated = (props) => {
  return (
    <>
      <div>User is not Logged In!</div>
      <button onClick={props.navigate(-1)}>Go Back</button>
    </>
  );
};
