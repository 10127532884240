import styled, { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./globalStyles";
import { lightTheme, darkTheme } from "./Themes";
import { useState } from "react";
import Sun from "../../assets/Images/Darkmode/Sun.png";
import Moon from "../../assets/Images/Darkmode/Moon.png";

export default function Darkmode() {
  const [theme, setTheme] = useState("light");

  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <StyledButton onClick={themeToggler}>
          {theme === "light" ? (
            <StyledImage src={Sun} />
          ) : (
            <StyledImage src={Moon} />
          )}
        </StyledButton>
      </>
    </ThemeProvider>
  );
}

const StyledButton = styled.button`
  border-color: transparent;
  border-radius: 8.5px;
  background-color: ${({ theme }) => theme.sunmoonbackground};
  color: ${({ theme }) => theme.text};
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 2;
`;

const StyledImage = styled.img`
  width: 70px;
  @media (max-width: 600px) {
    width: 100px;
  }
  @media (min-width: 900px) and (min-height: 2000px) {
    width: 600px;
  }
`;
