// import { validatePassword } from "firebase/auth";
import { useState } from "react";
import styled from "styled-components";
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"
// import { auth } from '../firebase-config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [startDate, setStartDate] = useState(new Date());

  let error = "Press Submit";

  let matchingEmail = false;
  let matchingPassword = false;
  let validEmail = false;
  let validPassword = false;
  // let Birthday = date;

  if (password === confirmPassword) {
    matchingPassword = true;
  } else {
    matchingPassword = false;
    error = "Passwords must match";
  }
  if (
    password.length >= 8 &&
    (password.includes("!") ||
      password.includes("?") ||
      password.includes("@") ||
      password.includes("#") === true)
  ) {
    validPassword = true;
  } else {
    validPassword = false;
    error =
      "Password must be equal to or longer than 8 digits and include ! ? @ or #";
  }
  if (email === confirmEmail) {
    matchingEmail = true;
  } else {
    matchingEmail = false;
    error = "Emails must match";
  }
  if (email.includes("@" && ".") === true && (matchingEmail = true)) {
    validEmail = true;
  } else {
    validEmail = false;
    error = "Email must include @ AND .";
  }

  const valid = () => {
    if (
      matchingEmail === true &&
      matchingPassword === true &&
      validEmail === true &&
      validPassword === true
    ) {
      alert("Account Created");
    } else {
      alert(`${error}`);
    }
  };

  return (
    <>
      <StyledDiv>
        <input
          placeholder="Enter an email..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <input
          placeholder="Confirm email..."
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
        />

        <br />
        <br />

        <input
          placeholder="Enter a Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <input
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </StyledDiv>

      <br />

      <div>When is your Birthday?</div>

      <DatePicker
        showMonthDropdown
        showYearDropdown
        shouldCloseOnSelect={false}
        isClearable
        withPortal
        // onBlur={handleOnBlur}
        popperPlacement="top-end"
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        filterDate={(date) => date.getFullYear() !== +2024}
        onChange={(date) => setStartDate(date)}
      />

      <br />

      <div>Birthday: {`${startDate}`}</div>

      <br />
      <br />

      <button onClick={valid}>Submit</button>

      <div>{`${error}`}</div>
    </>
  );
}

const StyledDiv = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  justify-content: center;
  padding-top: 30px;
`;
