import { useContext, createContext, useState, useMemo, useEffect } from 'react';
import { auth, db } from '../firebase-config';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';

const AuthContext = createContext({
  user: null,
  userDoc: null,
  signIn: () => {},
});

function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userDoc, setUserDoc] = useState(null);

  const signIn = async (email, password) => {
    try {
      const cred = await signInWithEmailAndPassword(auth, email, password);
      console.log(cred);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      console.log('current user: ', currentUser);
      try {
        console.log(
          currentUser
            ? ('Firebase auth state changed. UID: ', currentUser?.uid)
            : 'Logged out'
        );
        setUser(currentUser);
        if (!currentUser) return;
        console.log(currentUser);
        const docRef = doc(db, 'users', currentUser.uid);
        onSnapshot(docRef, (res) => {
          if (res.exists()) {
            console.log('Fetching user doc');
            return setUserDoc(res.data());
          }
          console.log('No doc for that id');
        });
      } catch (error) {
        console.error(error);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [auth, user]);

  const contextValue = useMemo(
    () => ({
      user,
      userDoc,
      signIn,
    }),
    [user, userDoc, signIn]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthContextProvider, useAuth };
