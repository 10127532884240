import styled from "styled-components";
import "./Image";

export default function ImageWithText(props) {
  return (
    <StyledDiv onClick={props.onClick}>
      <StyledImage src={props.src} key={props.key} />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: inline-flex;
  position: relative;
  text-align: center;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: transparent;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;

const StyledImage = styled.img`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 300px;
  width: auto;
  object-fit: cover;
  transition: 0.2s ease;
  &:hover {
    transform: scale(1.1);
    z-index: 1;
  }
`;
