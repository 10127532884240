import React from "react";
import styled from "styled-components";
import { getAuth, signOut } from "firebase/auth";

export default function SignOut() {
  function LogUserOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }
  return <StyledButton onClick={LogUserOut}>SignOut</StyledButton>;
}

const StyledButton = styled.button`
  position: absolute;
  left: 88%;
  bottom: 91.5%;
`;
