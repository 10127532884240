import React from 'react';

export default function Info() {
  return (
    <>
      <br />
      <div>Created By: Joshua Downes</div>
      <div>Email: joshuajdownes@gmail.com</div>
      <br />
      <div>
        Created Using: React (Combination of HTML and Javascript) - Not
        Squarespace, Webflow, Wordpress, Wix, etc.
      </div>
      <div>Created For: George Brennan</div>
      <br />
      <div>
        I am more of a web developer and not a web designer that is why it may
        not look amazing but it functions alright
      </div>
      <br />
      <div>Made for free (paid in experience lol)</div>
    </>
  );
}
