import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";

function PageNotFound() {
  const [counter, setCounter] = useState(3.0);
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 0.01), 10);
  }, [counter]);

  if (counter <= 0.01) {
    window.location.replace("/");
  }
  const error = "404";
  return (
    <StyledDiv>
      <h1>Page is not found!</h1>
      <h1>Redirecting to Home in {counter.toFixed(2)} seconds...</h1>
      <h1>Error {error}</h1>
      <StyledLink>
        {counter <= 0.69 ? <Link>If not please click here</Link> : <div></div>}
      </StyledLink>
    </StyledDiv>
  );
}

export default PageNotFound;

const StyledDiv = styled.div`
  font-size: 30px;
  color: black;
  text-decoration: none;
  text-align: center;
  text-transform: capitalize;
  text-align: center;
`;

const StyledLink = styled.div`
  &:link,
  &:visited {
    color: black;
  }

  &:hover {
    color: rgb(255, 188, 99);
    text-decoration: none;
  }

  &:active {
    color: darkgoldenrod;
  }
`;
