import styled from "styled-components";
import Logo from "../assets/Images/GeorgeBrennan.png";

const Image = (props) => {
  return (
    <div onClick={props.onClick}>
      <StyledImage src={Logo} alt="Logo" />
    </div>
  );
};

const StyledImage = styled.img`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  height: auto;
  border-radius: 20%;
`;

export default Image;
