import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function ContactMe() {
  const navigate = useNavigate();
  return (
    <StyledDiv>
      <p>Contact Information:</p>
      <p>George Brennan (Graphic Designer):</p>
      <StyledEmail href="https://mail.google.com/mail/u/0/?fs=1&to=GeorgeFrederickBrennan@gmail.com&su=&body=&tf=cm">
        GeorgeFrederickBrennan@gmail.com
      </StyledEmail>
      <br />
      <br />
      <p>Joshua Downes (Website Developer) - Website Issues:</p>
      <StyledEmail href="https://mail.google.com/mail/u/0/?fs=1&to=joshuajdownes@gmail.com&su=Website%20Issue%20/%20Inquiry&body=&tf=cm">
        JoshuaJDownes@gmail.com
      </StyledEmail>
      <br />
      <br />
      <StyledLink
        onClick={() => {
          navigate("/WebsiteInfo");
        }}
      >
        About the Website
      </StyledLink>
    </StyledDiv>
  );
}

export default ContactMe;

const StyledEmail = styled.a``;

const StyledDiv = styled.div`
  font-size: 25px;
  text-align: center;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;
