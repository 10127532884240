import { useNavigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import Darkmode from "../Darkmode/Darkmode";
import MediaLinks from "../MediaLinks";
import Logo from "../../assets/Images/GeorgeBrennan.png";

const Header = () => {
  return (
    <>
      <NavigationBar />
      <Outlet />
    </>
  );
};

export default Header;

const NavigationBar = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledImageDiv>
        <Image
          src={Logo}
          onClick={() => {
            navigate("/");
          }}
        />
      </StyledImageDiv>
      <StyledDiv>
        <StyledLink
          onClick={() => {
            navigate("/AboutMe");
          }}
        >
          About Me
        </StyledLink>
        <StyledLink
          onClick={() => {
            navigate("/");
          }}
        >
          Home
        </StyledLink>
        <StyledLink
          onClick={() => {
            navigate("/ContactMe");
          }}
        >
          Contact
        </StyledLink>
        <Darkmode />
      </StyledDiv>
      <StyledMediaDiv>
        <MediaLinks />
      </StyledMediaDiv>
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  inline-size: -webkit-fill-available;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  padding-top: 20px;
  gap: 20px;
`;

const StyledLink = styled.a`
  font-size: 25px;
  background-color: transparent;
  border: transparent;
  text-decoration: underline solid transparent;
  color: ${({ theme }) => theme.navbarbuttons};
  cursor: pointer;
  &:active {
    text-decoration: none;
  }
`;

const StyledMediaDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const Image = styled.img`
  width: 200px;
`;

const StyledImageDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;
