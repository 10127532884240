import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function AboutMe() {
  const navigate = useNavigate();
  const Button1 = (
    <StyledLink
      onClick={() => {
        navigate("/");
      }}
    >
      here
    </StyledLink>
  );
  const Button2 = (
    <StyledLink
      onClick={() => {
        navigate("/ContactMe");
      }}
    >
      here
    </StyledLink>
  );
  return (
    <StyledDiv>
      Hi, I'm George Brennan. You can find my work {Button1} and reach out to me{" "}
      {Button2} if you are interested in any of my work.
    </StyledDiv>
  );
}

export default AboutMe;

const StyledDiv = styled.div`
  margin-top: 30px;
  font-size: 25px;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  border: transparent;
  background-color: transparent;
  font-size: 25px;
  text-align: center;
  color: ${({ theme }) => theme.text};
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
  &:active {
    color: navy;
  }
`;
