import React from "react";
import styled from "styled-components";

import Instagram from "../assets/Images/Socials/InstagramLogo.png";
import Gmail from "../assets/Images/Socials/GmailLogo.png";
import LinkedIn from "../assets/Images/Socials/LinkedInLogo.png";
import YouTube from "../assets/Images/Socials/YouTubeLogo.png";
import TikTok from "../assets/Images/Socials/TikTokLogo.png";

export default function MediaLinks() {
  return (
    <StyledDiv>
      <div
        onClick={() =>
          (window.location.href =
            "https://www.Instagram.com/George_Brennan_Graphics/")
        }
      >
        <Image src={Instagram} />
      </div>

      <div
        onClick={() =>
          (window.location.href =
            "https://mail.google.com/mail/u/0/?fs=1&to=GeorgeFrederickBrennan@gmail.com&su=&body=&tf=cm")
        }
      >
        <Image src={Gmail} />
      </div>

      <div
        onClick={() =>
          (window.location.href =
            "https://www.LinkedIn.com/in/George-F-Brennan/")
        }
      >
        <Image src={LinkedIn} />
      </div>

      <div
        onClick={() =>
          (window.location.href =
            "https://www.YouTube.com/@GeorgeBrennanGraphics")
        }
      >
        <Image src={YouTube} />
      </div>
      <div
        onClick={() =>
          (window.location.href =
            "https://www.TikTok.com/@George.Brennan.Graphics")
        }
      >
        <Image src={TikTok} />
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Image = styled.img`
  padding-left: 10px;
  height: 30px;
  top: 75px;
  position: static;
  z-index: -1;
  cursor: pointer;
  transition: filter 0.25s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;
