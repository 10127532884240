// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBuDOPeVFoSjo2b-_xwKIn1hBlzetzlxns",
  authDomain: "george-brennan-website.firebaseapp.com",
  projectId: "george-brennan-website",
  storageBucket: "george-brennan-website.appspot.com",
  messagingSenderId: "110228946531",
  appId: "1:110228946531:web:2dac39b4f6bb6a8c288256",
  measurementId: "G-F339RZ42HL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

if (process.env.EMULATED_FIREBASE) {
  console.log('connecting to emulators');
  const hostname = window?.location?.hostname ?? 'localhost';
  connectFirestoreEmulator(db, hostname, 8080);
  connectStorageEmulator(storage, hostname, 9199);
  connectAuthEmulator(auth, hostname, 9099);
}
