import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { storage } from "../firebase-config";
import { ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import ImageWithText from "../components/ImageWithText";
import Logo from "../assets/Images/GeorgeBrennanLogo.png";

export default function MyWorks() {
  const [imageList, setImageList] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imageListRef = ref(storage, "images/");

  useEffect(() => {
    const getDocuments = async () => {
      const docRef = collection(db, "images");
      const docs = await getDocs(docRef);
      docs.forEach((doc) => {
        const downloadUrl = getDownloadURL(doc.data().path);
        setImageList((prev) => [...prev, downloadUrl]);
      });
    };

    getDocuments();
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        let obj = {};
        getMetadata(item).then((res) => {
          obj.createdTime = new Date(res.timeCreated);
        });

        getDownloadURL(item).then((url) => {
          obj.url = url;
          let filename = item.name;
          let split = filename.split(".");
          const filenameWithoutExt = split[0];
          obj.name = filenameWithoutExt;
          console.log(filenameWithoutExt);
          setImageList((prev) => [...prev, obj]);
        });
      });
    });
  }, []);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? imageList.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === imageList.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <StyledDiv>
      <div>
        <StyledImage src={Logo} />
      </div>
      <StyledImageContainer>
        {imageList
          .sort((a, b) => b.createdTime - a.createdTime)
          .map((obj, index) => {
            return (
              <ImageWithText
                key={obj.url}
                src={obj.url}
                text={obj.name}
                onClick={() => handleImageClick(index)}
              />
            );
          })}
      </StyledImageContainer>
      {isModalOpen && (
        <Modal onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
            <NavigationButton onClick={goToPreviousImage}>
              &lt;
            </NavigationButton>
            <ModalImage
              src={imageList[selectedImageIndex].url}
              alt={imageList[selectedImageIndex].name}
            />
            <NavigationButton onClick={goToNextImage}>&gt;</NavigationButton>
            <ModalText>{imageList[selectedImageIndex].name}</ModalText>
          </ModalContent>
        </Modal>
      )}
    </StyledDiv>
  );
}

const StyledImage = styled.img`
  width: 100%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: auto;
`;

const StyledImageContainer = styled.div`
  display: inline-flex;
  position: relative;
  text-align: center;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: transparent;
  margin: 20px;
  &:hover {
    color: white;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 700px;
  text-align: center;
  position: relative;
`;

const CloseButton = styled.span`
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 28px;
  font-weight: bold;
  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

const NavigationButton = styled.span`
  color: #aaa;
  position: absolute;
  top: 50%;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  &:hover,
  &:focus {
    color: black;
  }
  &:nth-child(2) {
    left: 10px;
  }
  &:nth-child(4) {
    right: 10px;
  }
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
`;

const ModalText = styled.p`
  margin-top: 15px;
  font-size: 18px;
`;
