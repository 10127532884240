import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router";

export default function Admin() {
  const { signIn, user } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = async () => {
    try {
      await signIn(email, password);
    } catch (error) {
      console.log(error);
    }
  };

  if (user) return <Navigate to={"/admin/upload"} />;

  return (
    <>
      <div>Log In:</div>
      <input
        placeholder="Enter Email..."
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <input
        type="password"
        placeholder="Enter Password..."
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <br />
      <StyledButton onClick={login}>Login</StyledButton>
    </>
  );
}

const StyledButton = styled.button``;
