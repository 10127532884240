import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { AuthContextProvider } from "./hooks/useAuth";
import AdminLayoutControl from "./components/Layouts/AdminLayoutControl";

import AboutMe from "./pages/AboutMe";
import MyWorks from "./pages/MyWorks";
import ContactMe from "./pages/ContactMe";

import WebsiteInfo from "./pages/WebsiteInfo";

import PageNotFound from "./pages/404";

import SignUp from "./pages/SignUp";
import Upload from "./pages/adminpages/Upload";
import Admin from "./pages/adminpages/Admin";

import Header from "./components/Layouts/Header";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Header />,
      children: [
        { path: "", element: <MyWorks /> },
        { path: "*", element: <PageNotFound /> },
        { path: "aboutme", element: <AboutMe /> },
        { path: "contactme", element: <ContactMe /> },
        { path: "signup", element: <SignUp /> },
        { path: "websiteinfo", element: <WebsiteInfo /> },

        {
          path: "admin",
          element: <AdminLayoutControl />,
          children: [
            { path: "", element: <Admin /> },
            { path: "upload", element: <Upload /> },
          ],
        },
      ],
    },
  ]);

  return (
    <>
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
    </>
  );
};

export default App;
