import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";

import SignOut from "./SignOut";

import { db, storage } from "../../firebase-config";
import {
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  deleteObject,
  getMetadata,
} from "firebase/storage";
import ImageWithText from "../../components/ImageWithText";
import { getDocs, collection } from "firebase/firestore";

export default function Upload() {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageList, setImageList] = useState([]);
  const imageListRef = ref(storage, "images/");

  const [sortedBy, setSortedBy] = useState("Newest");

  const uploadImage = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name}`);
    uploadBytes(imageRef, imageUpload).then(() => {
      alert("Image Uploaded");
    });
  };

  const deleteImage = (imageRef) => {
    console.log(imageRef);
    if (window.confirm("Press OK if you want to delete the Image") === true) {
      deleteObject(imageRef);
    } else {
      // Don't Delete;
    }
  };

  useEffect(() => {
    const getDocuments = async () => {
      const docRef = collection(db, "images");
      console.log(docRef);
      const docs = await getDocs(docRef);
      docs.forEach((doc) => {
        const downloadUrl = getDownloadURL(doc.data().path);
        setImageList((prev) => [...prev, downloadUrl]);
      });
    };
    getDocuments();
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        let obj = {};
        getMetadata(item).then((res) => {
          obj.createdTime = new Date(res.timeCreated);
        });

        getDownloadURL(item).then((url) => {
          obj.url = url;
          obj.imageRef = item;
          let filename = item.name;
          let split = filename.split(".");
          const filenameWithoutExt = split[0];
          // let slicefiletype = split.slice(0,1)
          // let name = slicefiletype.toString();
          obj.name = filenameWithoutExt;
          console.log(filenameWithoutExt);
          setImageList((prev) => [...prev, obj]);
        });
      });
    });
  }, []);

  return (
    <MainDiv>
      <SignOut />
      <div>
        IMPORTANT! The Image name is what the file is saved as, change what the
        file is called to change the name.
      </div>
      <div>
        <input
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/webp"
          onChange={(event) => {
            setImageUpload(event.target.files[0]);
          }}
        />
      </div>
      <div>
        <button onClick={uploadImage}>Upload Image</button>
      </div>
      <StyledDropdownDiv>
        <p>Sorted by: {`${sortedBy}`}</p>
        <div onClick={() => setSortedBy("Newest")}>
          <button>Sort By Newest</button>
        </div>
        <div onClick={() => setSortedBy("Oldest")}>
          <button>Sort By Oldest</button>
        </div>
      </StyledDropdownDiv>
      <MiniDiv>
        {sortedBy === "Newest"
          ? imageList
              .sort((a, b) => b.createdTime - a.createdTime)
              .map((obj) => {
                return (
                  <ImageWithText
                    src={obj.url}
                    text={"Delete Image"}
                    onClick={() => deleteImage(obj.imageRef)}
                  />
                );
              })
          : imageList
              .sort((a, b) => a.createdTime - b.createdTime)
              .map((obj) => {
                return (
                  <ImageWithText
                    src={obj.url}
                    text={"Delete Image"}
                    onClick={() => deleteImage(obj.imageRef)}
                  />
                );
              })}
      </MiniDiv>
    </MainDiv>
  );
}

const MainDiv = styled.div``;

const MiniDiv = styled.div``;

const StyledDropdownDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
